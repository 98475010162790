import * as React from 'react'
import { graphql, Link } from 'gatsby'

import { Button, Table } from 'react-bootstrap';


const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = React.useState(config);
  
  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = key => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  }

  return { items: sortedItems, requestSort, sortConfig };
}

function QuestionTable(props) {
  const { items, requestSort, sortConfig } = useSortableData(props.questions);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };
  return (
    <Table bordered hover>
      <thead>
        <tr>
          <th>
            <Button className={getClassNamesFor('Name')} variant="link" onClick={() => requestSort('Name')}>
              Question
            </Button>
          </th>
          <th>
            <Button className={getClassNamesFor('Level')} variant="link" onClick={() => requestSort('Level')}>
              Level of Depth
            </Button>
          </th>
          <th>
            <Button className={getClassNamesFor('Tags')} variant="link" onClick={() => requestSort('Tags')}>
              Topics
            </Button>
          </th>
        </tr>
      </thead>
      <tbody>
        { items.map((question, index) => {
          return (
            <tr key={index}>
              <td>{question.Name}</td>
              <td>{question.Level}</td>
              <td>{question.Tags}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  )
}

const QuestionsPage = ({data}) => {
  return (
    <main>
      <title>All Questions</title>
      <div className="container">
        <p className="text-left"><Link to="/">Go back home</Link></p>
        <h1>All Questions</h1>
        <QuestionTable questions={data.allQuestionsCsv.nodes}/>
      </div>
    </main>
  )
}

export const query = graphql`
  query QuestionsPageQuery {
    allQuestionsCsv(sort: {fields: Name}){
      nodes {
        Level
        Name
        Tags
      }
    }
  }
`

export default QuestionsPage
